import React from 'react';
import { Button, DurationInput, SearchInput } from '../../../../core/ui/components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ObjectSchema, Shape, ValidateOptions } from 'yup';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
    Grid,
    List,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    TextareaAutosize,
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/en-gb';
import DateUtils from '@date-io/moment';
import { causesActions, FormActions, statesActions, statesOverridesActions } from '../../../../core/actions';
import { connect } from 'react-redux';
import {
    ICause,
    IStateData, IStateFormFormValues, IStateFormModel, IStateFormProps, IStateFormState,
} from '../../../../core/interfaces';
import { Scrollbar } from 'react-scrollbars-custom';
import { ReactComponent as DropdownArrowIcon } from '../../../../core/ui/assets/images/icons/arrow-expanded.svg';
import momentDurationFormatSetup from 'moment-duration-format';

import './StateForm.scss';
import * as d3 from 'd3';
import { ScaleTime } from 'd3';
import TimeGroupItemStateForm from './TimeGroupItemStateForm';
import { RootState } from '../../../../core/store';
import StateItem from './StateItem';
import { selectCurrentUser } from '../../../../core/selectors/auth/authSelector';
import { AppRoles } from '../../../../rbac/roles';
import { selectStateChangeUnitSensor } from '../../../../core/selectors/stateChange/stateChangeSelector';
import { appConfig } from '../../../../config/appConfig';

/**
 * add format duration function
 */
momentDurationFormatSetup.bind(moment());

class StateForm extends React.Component<IStateFormProps & WithTranslation, IStateFormState> {

    constructor(props: IStateFormProps & WithTranslation) {
        super(props);

        const { model, t } = this.props;

        const initialValuesStartTime = model?.startTime ? model.startTime : new Date(),
            initialValuesEndTime = model?.endTime ?
                moment(model.endTime).isAfter(new Date()) ? new Date() : model.endTime : new Date(),
            initialValuesDuration = model ? moment.duration(moment(model.endTime)
                .diff(moment(model.startTime)), 'milliseconds').asMilliseconds() : 0;

        this.state = {

            initialValues: {
                endTime: initialValuesEndTime,
                startTime: initialValuesStartTime,
                duration: initialValuesDuration,
                comment: model?.comment ? model.comment : '',
                name: model?.stateCategoryName ? model.stateCategoryName : '',
                cause: model?.causeName ? model.causeName : null,
                stateCategoryId: model?.stateCategoryId,
                causeId: model?.causeId,
            },
            confirm: false,
            searchField: '',
            durationError: false,
            startTimeError: false,
            endTimeError: false,
            downTimeError: false,
            causeValidError: false,
            formValue: null,
            startChange: false,
        };

        this.validationSchema = yup.object().shape({
            name: yup
                .string(),
            comment: yup.string(),
            endTime: yup.date()
                .min(yup.ref('startTime'), t('MIN_END_TIME_TEXT_ERROR'))
                .max(yup.ref('currentDate'), t('MAX_TIME_CURRENT_DATE_TEXT_ERROR'))
                .required('REQUIRED'),
            startTime: yup.date()
                .max(yup.ref('endTime'), t('MAX_START_TIME_TEXT_ERROR'))
                .required('REQUIRED'),
            duration: yup.number()
                .min(9999, t('DURATION_TEXT_ERROR'))
                .required('REQUIRED'),
            currentDate: yup.date().default(() => new Date()),
        });

        this.scale = d3.scaleTime();
        this.indexState = null;

        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleDateChange = this.handleDateChange.bind(this);

        this.errorHandlerStartTime = this.errorHandlerStartTime.bind(this);

        this.errorHandlerEndTime = this.errorHandlerEndTime.bind(this);

        this.errorHandlerDuration = this.errorHandlerDuration.bind(this);

        this.addDuration = this.addDuration.bind(this);

        this.searchHandler = this.searchHandler.bind(this);

        this.handleCancel = this.handleCancel.bind(this);

        this.prevNextAction = this.prevNextAction.bind(this);

        this.sidebarCloseHandler = this.sidebarCloseHandler.bind(this);

        this.selectStateItem = this.selectStateItem.bind(this);

        this.errorHandlerDownTime = this.errorHandlerDownTime.bind(this);

        this.checkCauseIsValid = this.checkCauseIsValid.bind(this);

        this.errorHandlerCauseValid = this.errorHandlerCauseValid.bind(this);

        this.stateCategoryList = this.stateCategoryList.bind(this);
    }

    /**
     * Callback after render the component to the DOM
     */
    componentDidMount() {

        const { loadCauses } = this.props;

        this.props.loadStates('', { column: 'id', dir: 'asc' }, { table: ['causes'] });

        if (loadCauses) {

            loadCauses('', { column: 'id', dir: 'asc' }, undefined, { field: ['stateCategory||$isnull'] });
        }
    }

    /**
     * Component props update handler
     *
     * @param {IProps} prevProps Updated component properties
     */
    componentDidUpdate(prevProps: Readonly<IStateFormProps & WithTranslation>): void {

        if (this.props.model !== prevProps.model) {

            this.updateHandler();
        }

        // Fixed problem with QVB-950
        // if (this.props.sensorsData !== prevProps.sensorsData) {
        //
        //     this.updateHandlerData();
        // }

        if (prevProps.dataState && !this.props.dataState) {

            this.sidebarCloseHandler();
        }

        this.updateStateChart();
    }


    /**
     * Scale time
     *
     * @type {ScaleTime<number, unknown>}
     */
    private scale: ScaleTime<number, number>;

    /**
     * Form validation schema
     *
     * @type {ObjectSchema<Shape<ValidateOptions, {name: string}>>}
     * @private
     */
    private readonly validationSchema: ObjectSchema<Shape<ValidateOptions, { name: string }>>;

    /**
     * Current index for state
     *
     * @type {number | null}
     * @private
     */
    private indexState: number | null;


    /**
     * Dashboard form submit handler
     *
     * @param {IFormValues} values
     */
    handleSubmit(values: IStateFormFormValues) {
       
        const causeIsValid = values.causeId ? this.checkCauseIsValid(values.causeId) : true;

        if (
            !this.state.startTimeError 
            && !this.state.endTimeError 
            && !this.state.durationError 
            && !this.state.downTimeError
            && causeIsValid
        ) {

            const { toggleForm, storeStateOverride, dataState, model, brushRange, screenWidth } = this.props;

            if (!dataState) {

                return;
            }

            if (values) {

                const updateOptions = brushRange ? { ...brushRange, screenWidth } : undefined;

                storeStateOverride({
                    sensor: dataState?.id || 0,
                    stateCategory: values.stateCategoryId ? values.stateCategoryId : null,
                    cause: values.causeId ? values.causeId : null,
                    comment: values.comment.trim(),
                    startTime: values.startTime,
                    endTime: values.endTime,
                }, updateOptions);

                if (model?.startTime !== values.startTime || model.endTime !== values.endTime) {

                    this.props.deselectState();

                }
            }

            this.handleCancel();

            toggleForm(this.props.openSidebar);

        }
    }

    /**
     * Dashboard form cancel handler
     */
    handleCancel() {

        this.setState({ confirm: false, formValue: null });
    }

    /**
     * Date change handler
     *
     * @param {IFormValues} values
     */
    handleDateChange(values: IStateFormFormValues) {

        values.duration = moment.duration(moment(values.endTime)
            .diff(moment(values.startTime)), 'milliseconds').asMilliseconds();

        this.setState({ initialValues: values, startChange: true });

    }

    /**
     * Search filter handler
     *
     * @param data
     */
    searchHandler(data: string) {

        this.setState({ searchField: data });
    }

    /**
     * Update handler
     */
    updateHandler() {

        const { model } = this.props;

        if (model) {

            this.setState({
                ...this.state,
                initialValues: {
                    endTime: model.endTime,
                    startTime: model.startTime,
                    duration: moment.duration(moment(model.endTime).diff(moment(model.startTime)), 'milliseconds').asMilliseconds(),
                    comment: model?.comment ? model.comment : '',
                    name: model?.stateCategoryName ? model.stateCategoryName : '',
                    cause: model?.causeName ? model.causeName : null,
                    stateCategoryId: model?.stateCategoryId,
                    causeId: model.causeId,
                },
            });
        }
    }

    /**
     * Date update handler
     */
    // updateHandlerData() {
    //
    //     const { sensorsData, currentSelection } = this.props;
    //
    //     const currentSensorData = sensorsData as IStateData;
    //
    //     if (currentSensorData) {
    //         const index = currentSensorData.states.findIndex(value => value.startTimeOriginal === currentSelection?.endTimeOriginal);
    //
    //         const data = currentSensorData.states[index];
    //         if (data) {
    //             this.setState({
    //                 initialValues: {
    //                     endTime: data.endTime,
    //                     startTime: data.startTime,
    //                     duration: moment.duration(moment(data.endTime).diff(moment(data.startTime)), 'milliseconds').asMilliseconds(),
    //                     comment: data?.comment ? data.comment : '',
    //                     name: data?.stateCategoryName ? data.stateCategoryName : '',
    //                     cause: data?.causeName ? data.causeName : null,
    //                     stateCategoryId: data?.stateCategoryId,
    //                     causeId: data.causeId,
    //                 },
    //             });
    //         }
    //     }
    // }

    /**
     * Set new and time with duration
     *
     * @param {Date} date
     */
    addDuration(date: Date) {

        const { initialValues } = this.state;

        const { model, downTimeRestriction } = this.props;

        const modelDiff = model?.startTime && model?.endTime 
            ? moment.duration(moment(model.endTime).startOf('second').diff(moment(model.startTime).startOf('second')), 'milliseconds').asMilliseconds() : null; 
    
        initialValues.endTime = date;

        initialValues.duration = moment.duration(moment(initialValues.endTime)
            .diff(moment(initialValues.startTime)), 'milliseconds').asMilliseconds();

        if (downTimeRestriction && modelDiff && modelDiff < initialValues.duration) {
            this.setState({ downTimeError: true });
        } else {
            this.setState({ initialValues: { ...initialValues }, downTimeError: false });
        
            this.forceUpdate();
        }
    }

    /**
     * Time update handler
     *
     * @param {Date} data
     * @param {string} name
     */
    timeChange(data: Date, name: string) {

        const { initialValues } = this.state;

        if (name === 'startDate') {

            if (moment(initialValues.endTime).isAfter(data)) {

                initialValues.startTime = data;

            } else {

                initialValues.startTime = initialValues.endTime;
            }

            initialValues.duration = moment.duration(moment(initialValues.endTime)
                .diff(moment(initialValues.startTime)), 'milliseconds').asMilliseconds();

            this.setState({ initialValues: { ...initialValues } });
        }

        if (name === 'endDate') {

            if (moment(data).isAfter(initialValues.startTime)) {

                initialValues.endTime = data;

            }

            if (moment(data).isBefore(initialValues.startTime)) {

                initialValues.endTime = initialValues.startTime;
            }

            initialValues.duration = moment.duration(moment(initialValues.endTime)
                .diff(moment(initialValues.startTime)), 'milliseconds').asMilliseconds();

            this.setState({ initialValues: { ...initialValues } });
        }


        this.forceUpdate();
    }

    /**
     *  Sort cause by orderInCategory
     *
     * @param {IProduct[]} value
     * @return {IProduct[]}
     */
    sortOrderInCategory(value: ICause[]) {

        return value.sort((a, b) => {

            if (a.position === undefined) {

                a.position = 0;
            }

            if (b.position === undefined) {

                b.position = 0;
            }

            return a.position < b.position ? -1 : 1;
        });

    }

    /**
     * Select cause in [] of category
     *
     * @param {number}id
     *
     * @param {string} searchField
     * @return {Object[]}
     */
    categoryData(id: number, searchField: string): ICause[] | null {

        const { states } = this.props,
            data = states?.filter(value => value.id === id);
        
        if (searchField === '') {
            return data && data?.length > 0 ? this.sortOrderInCategory(data[0].causes) : null;
        } else {

            return data && data?.length > 0 ? this.sortOrderInCategory(data[0].causes)
                .filter((value: { name: string }) => {
                    return value.name.toLowerCase().includes(searchField.toLowerCase());
                }) : null;
        }
    }

    /**
     * Check cause to valid
     */
    checkCauseIsValid(id: number): boolean {
        
        let causes: ICause[] = [];

        for (const category of this.props.states) {

            if (category.causes) {
                causes = [...causes, ...category.causes];
            }
        }

        const item = causes.find(cause => cause.id === id);
    
        this.errorHandlerCauseValid(!item);

        return !!item;
    }

    /**
     * Navigation action
     *
     * @param {React.MouseEvent<HTMLButtonElement>} event
     */
    prevNextAction(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();

        const direction = event.currentTarget.getAttribute('itemId');

        if (this.props.model) {

            const { sensorsData, dataUnit, currentSelection } = this.props;

            const currentSensorData = sensorsData as IStateData;

            if (currentSensorData && this.props.storeFormData && this.props.selectState) {

                if (!this.indexState) {

                    this.indexState = currentSensorData.states.findIndex(value => value.startTimeOriginal === currentSelection?.startTimeOriginal);
                }

                if (this.indexState && this.indexState - 1 >= 0 && direction === 'prev') {

                    const data = currentSensorData.states[this.indexState - 1];
                    this.props.selectState(data, this.getPositionElement(data), currentSensorData);

                    this.props.storeFormData({ data: data, unit: dataUnit });

                    this.indexState = this.indexState - 1;

                }
                if (this.indexState >= 0 && this.indexState + 1 < currentSensorData.states.length && direction === 'next') {

                    const data = currentSensorData.states[this.indexState + 1];

                    this.props.selectState(data, this.getPositionElement(data), currentSensorData);

                    this.props.storeFormData({ data: data, unit: dataUnit });

                    this.indexState = this.indexState + 1;
                }

                this.updateHandler();
            }

        }
    }

    /**
     * The calculated current position of an element.
     *
     * @param {Object} value
     */
    getPositionElement(value: {
        color: string;
        endTime: Date;
        comment: string;
        startTime: Date;
        value: number;
        causeId: number;
        causeName: string;
        stateCategoryId: number
        stateCategoryName: string;
    }) {

        return {
            left: this.scale(moment(value.startTime).toDate()),
            width: this.scale(moment(value.endTime).toDate()) - this.scale(moment(value.startTime).toDate()),
        };

    }

    /**
     * State chart Update Features
     */
    updateStateChart() {
        if (this.props.screenWidth && this.props.selection) {
            const { selection, dashboardOnline } = this.props;
            const newSelection = selection;

            if (dashboardOnline) {

                if (newSelection[0] > new Date()) {

                    this.scale = d3.scaleTime()
                        .range([0, this.props.screenWidth + 40])
                        .domain(newSelection);

                } else {

                    this.scale = d3.scaleTime()
                        .range([0, this.props.screenWidth])
                        .domain(newSelection);

                }
            } else {

                this.scale = d3.scaleTime()
                    .range([0, this.props.screenWidth])
                    .domain(newSelection);
            }
        }
    }

    /**
     * Error handler for Start Time
     *
     * @param {boolean} event
     */
    errorHandlerStartTime(event: boolean) {
        this.setState({ startTimeError: event });
    }

    /**
     * Error handler for End Time
     *
     * @param {boolean} event
     */
    errorHandlerEndTime(event: boolean) {
        this.setState({ startTimeError: event });
    }

    /**
     * Error handler for Duration
     *
     * @param {boolean} event
     */
    errorHandlerDuration(event: boolean) {

        this.setState({ durationError: event });
    }

    /**
     * Error handler for downtime restriction
     * 
     * @param {boolean} event 
     */
    errorHandlerDownTime(event: boolean) {

        this.setState({ downTimeError: event });
    }

    errorHandlerCauseValid(event: boolean) {
        this.setState({ causeValidError: event });
    }

    /**
     * Sidebar close handler
     */
    sidebarCloseHandler() {

        this.props.toggleForm(this.props.openSidebar);
    }

    /**
     * Select state item
     *
     * @param {{causeName: string, causeId: number, stateCategoryId: number, comment: string}} data
     */
    selectStateItem(data: {
        causeName: string;
        causeId: number;
        stateCategoryId: number;
        comment: string;
    }) {

        this.errorHandlerCauseValid(false);

        this.setState({
           initialValues: {
               ...this.state.initialValues,
               cause: data.causeName,
               causeId: data.causeId,
               stateCategoryId: data.stateCategoryId,
               comment: data.comment,
           },
       });
    }

    /**
     * Check access to change state interval
     *
     * @param {IStateFormModel | null} model
     * @returns {boolean}
     */
    checkAccessChangeInterval(model: IStateFormModel | null): boolean {
        
        const { downTimeRestriction = false } = this.props;

        return (downTimeRestriction && model?.causeId !== undefined);
    }

    getStartTime(start: Date, startSelection?: Date[]): Date {

        const { startChange } = this.state;

        if (startSelection && new Date(start).getTime() < new Date(startSelection[0]).getTime() && !startChange) {

            return startSelection[1];
        }
        return start;
    }

    /**
     * Get list of state categories with filtration by search field 
     * 
     * @returns {any[]}
     */
    stateCategoryList(): any[] {

        const { states } = this.props;

        const { searchField } = this.state;

        if (states.length && searchField.length) {

            const result: any = [];

            states.forEach(state => {

                const causes = state?.causes?.filter((cause: ICause) => cause?.name.toLowerCase().includes(searchField)) || [];

                if (state?.name?.toLowerCase().includes(searchField) || causes.length) {

                    result.push({
                        ...state,
                        causes,
                    });
                }
            });

            return result;
        }

        return states;
    }

    render() {

        this.updateStateChart();

        const { initialValues, downTimeError } = this.state;

        const { t, states, dataUnit, model, commentRequired, downTimeRestriction, selection } = this.props;

        const disableStateCalendar = this.checkAccessChangeInterval(model);

        return (
            <React.Fragment>
                <div className="state-form">
                    <div className="header">
                        <div className="btn-group">
                            <Button
                                size={'small'}
                                color={'primary'}
                                itemID="prev"
                                onClick={this.prevNextAction}
                            >{this.props.t('PREVIOUS_STATE')}
                            </Button>
                            <Button
                                size={'small'}
                                color={'primary'}
                                itemID={'next'}
                                onClick={this.prevNextAction}
                            >{this.props.t('NEXT_STATE')}
                            </Button>
                        </div>
                        <div className="title"><b>{this.props.t('SET_THE_STATE')} </b>(
                            <span
                                style={{ textTransform: 'capitalize' }}
                            >
                                {dataUnit && dataUnit.name ? dataUnit.name : ''}
                            </span>)
                        </div>
                    </div>
                    <div className="body">
                        <Formik
                            enableReinitialize
                            initialValues={initialValues}
                            validationSchema={this.validationSchema}
                            onSubmit={this.handleSubmit}
                        >
                            {props => (
                                <form onSubmit={props.handleSubmit}
                                      noValidate
                                >
                                    <Grid container className={'time-group'}>
                                        <MuiPickersUtilsProvider
                                            utils={DateUtils}
                                            locale={localStorage.getItem('language')}
                                            libInstance={moment}
                                        >
                                            <TimeGroupItemStateForm
                                                // value={this.getStartTime(props.values.startTime, selection)}
                                                value={props.values.startTime}
                                                maxDate={props.values.endTime}
                                                minDate={downTimeRestriction && model ? model.startTime : undefined}
                                                datePickerClass={'calendar-picker-startDate'}
                                                datePickerId={'date-picker-start-date'}
                                                label={this.props.t('START_TIME')}
                                                datePosition={'startDate'}
                                                handleDateChange={this.handleDateChange}
                                                errorHandler={this.errorHandlerStartTime}
                                                values={props.values}
                                                disabled={disableStateCalendar}
                                                downTimeRestriction={downTimeRestriction}
                                                downTimeErrorHandler={this.errorHandlerDownTime}
                                            />
                                        </MuiPickersUtilsProvider>
                                        <DurationInput
                                            startTime={props.values.startTime}
                                            endTime={props.values.endTime}
                                            label={this.props.t('DURATION')}
                                            newTime={this.addDuration}
                                            onError={this.errorHandlerDuration}
                                            disabled={disableStateCalendar}
                                            downTimeRestriction={downTimeRestriction}
                                            downTimeErrorHandler={this.errorHandlerDownTime}
                                        />
                                        <MuiPickersUtilsProvider
                                            utils={DateUtils}
                                            locale={localStorage.getItem('language')}
                                            libInstance={moment}
                                        >
                                            <TimeGroupItemStateForm
                                                value={moment(props.values.endTime).toDate()}
                                                maxDate={downTimeRestriction ? moment(props.values.endTime).toDate() : new Date()}
                                                minDate={moment(props.values.startTime).toDate()}
                                                datePickerClass={'calendar-picker-endDate'}
                                                datePickerId={'date-picker-end-date'}
                                                label={this.props.t('END_TIME')}
                                                datePosition={'endDate'}
                                                handleDateChange={this.handleDateChange}
                                                errorHandler={this.errorHandlerEndTime}
                                                values={props.values}
                                                disabled={disableStateCalendar}
                                                downTimeRestriction={downTimeRestriction}
                                                downTimeErrorHandler={this.errorHandlerDownTime}
                                            />
                                        </MuiPickersUtilsProvider>

                                        <div className="form-group error">
                                            {((props.touched.startTime && props.errors.startTime)) &&
                                            <div className="validation-massage">{props.errors.startTime}</div>
                                            }
                                        </div>
                                        <div className="form-group error">
                                            {((props.touched.endTime && props.errors.endTime)) &&
                                            <div className="validation-massage">{props.errors.endTime}</div>
                                            }
                                        </div>
                                        <div className="form-group error">
                                            {((props.touched.duration && props.errors.duration)) &&
                                            <div className="validation-massage">{props.errors.duration}</div>
                                            }
                                        </div>

                                        <div className="form-group error">
                                            {this.state.durationError && ((!props.touched.duration && !props.errors.duration)) &&
                                            <div className="validation-massage">{t('FORMAT_INPUT_ERROR')}</div>
                                            }
                                        </div>
                                        <div className="form-group error">
                                            {(this.state.startTimeError || this.state.endTimeError) &&
                                            <div className="validation-massage">{t('FORMAT_TIME_ERROR')}</div>
                                            }
                                        </div>
                                        <div className="form-group error">
                                            {!this.state.startTimeError && !this.state.durationError  && downTimeError && ((!props.touched.duration && !props.errors.duration)) &&
                                            <div className="validation-massage">{t('INTERVAL_DOWNTIME_RESTRICTION')}</div>
                                            }
                                        </div>
                                    </Grid>
                                    <div className="category-wrap">
                                        <div className="category-header">
                                            {t('CHOOSE_CATEGORY')}
                                        </div>
                                        <div className="form-group error" style={{ marginBottom: '10px' }}>
                                            {this.state.causeValidError &&
                                                <div className="validation-massage">{t('STATE_IS_INVALID')}</div>
                                            }
                                        </div>
                                        <div className="category-body">
                                            <SearchInput
                                                searchHandler={this.searchHandler}
                                                options={{}}
                                                placeholder={t('SEARCH')}
                                            />
                                            <Scrollbar
                                                noScrollX
                                                removeTracksWhenNotUsed
                                                width={'100%'}
                                                style={{ height: 426 }}
                                            >
                                                {states && this.stateCategoryList().map(stateCategory => (
                                                    <Accordion key={stateCategory.id} defaultExpanded>
                                                        <AccordionSummary>
                                                            <div className="category-name-box">
                                                                <div className="category-name">
                                                                    <DropdownArrowIcon />
                                                                    <div className="color-circle"
                                                                         style={{
                                                                             background: stateCategory.color,
                                                                         }}
                                                                    />
                                                                    <div className="wrap-name">
                                                                        {stateCategory.name}
                                                                    </div>
                                                                    <span className="category-line" />
                                                                </div>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <List disablePadding>
                                                                {this.categoryData(parseInt(stateCategory.id), this.state.searchField)
                                                                    ?.map(stateItem => (
                                                                    <StateItem 
                                                                        key={stateItem.id} 
                                                                        stateItem={stateItem}
                                                                        values={props.values}
                                                                        stateCategory={stateCategory}
                                                                        onClickHandler={this.selectStateItem}
                                                                    />
                                                                ))}
                                                            </List>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ))}
                                            </Scrollbar>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <TextareaAutosize
                                            aria-label="comment"
                                            className={
                                                'form-field comment '
                                                +
                                                (props.touched.comment ? props.errors.comment ? 'error-field' : 'success-field' : '')
                                            }
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.comment}
                                            name="comment"
                                            maxRows={1}
                                            placeholder={this.props.t('COMMENT') + '...'}
                                        />
                                        {((props.touched.comment && props.errors.comment)) &&
                                        <div className="validation-massage">{props.errors.comment}</div>
                                        }
                                        {model && model.updatedAt && model.user &&
                                        <div className="info-override">
                                            {`${model.user.username} ${moment(model.updatedAt).format('DD.MM.YYYY HH:mm')} ${t('EDITED')}`}
                                        </div>
                                        }
                                    </div>
                                    <div className="pinned-button-group">
                                        <div className="form-group btn-group">

                                            <Button
                                                type="reset"
                                                color={'primary'}
                                                onClick={this.sidebarCloseHandler}
                                            >{t('CANCEL')}
                                            </Button>
                                            <Button
                                                type="submit"
                                                color={'secondary'}
                                                disabled={
                                                    (!this.state.initialValues.causeId 
                                                        && !this.state.initialValues.stateCategoryId) 
                                                    || (commentRequired && !props.values.comment.trim().length)
                                                }
                                            >
                                                {t(this.props.model?.causeId ? 'SAVE_CHANGES' : 'ADD')}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    toggleForm: FormActions.toggle,
    loadStates: statesActions.list,
    loadCauses: causesActions.list,
    storeFormData: statesActions.storeEditData,
    selectState: statesActions.selectState,
    deselectAllStates: statesActions.deselectAllStates,
    deselectState: statesActions.deselectState,
    storeStateOverride: statesOverridesActions.store,
    updateStateOverride: statesOverridesActions.update,
    removeStateOverride: statesOverridesActions.delete,
});


const mapStateToProps = (state: RootState) => {

    const { errors, states } = state.statesList,
        { causes } = state.causesList,
        { formOpened, formName } = state.form,
        { unit } = state.stateChange,
        { range } = state.graphPeriod;

    const user = selectCurrentUser(state);

    const sensorId = state.stateSelection.states?.sensorId || state.graphBarTableVisibility.sensorId;
    const sensorsData = (!state.graphMinimapBrush.brushRule ? state.dashboard.sensorsData : state.dashboard.sensorsDataRange).find((sensorData) => sensorData.sensorId === sensorId
        && sensorData.type === 'state') as IStateData;

    const sensorItem = selectStateChangeUnitSensor(state, state.stateSelection.states?.id);

    const commentRequired = appConfig.stateCommentRequired
        && sensorItem 
        && !sensorItem.name.includes(appConfig.umanSensorSubString);

    const downTimeRestriction =
        appConfig.notDownTimeSensorSubString.length 
        && sensorItem 
        && user.role !== AppRoles.SUPER
        && !sensorItem.name.includes(appConfig.notDownTimeSensorSubString);

    return {
        errors,
        states,
        unsorted: causes,
        openSidebar: formOpened,
        formName: formName,
        selection: state.graphMinimapBrush.selection,
        screenWidth: state.dashboard.screenWidth,
        dashboardOnline: state.dashboard.online,
        sensorsData,
        dataState: state.stateSelection.states || sensorsData,
        currentSelection: state.stateSelection.state,
        unit,
        brushRange: range,
        commentRequired,
        downTimeRestriction: Boolean(downTimeRestriction),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StateForm));
