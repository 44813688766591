import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IErrors, IStatesOverride } from '../interfaces';
import { StatesOverridesService, GraphDataService } from '../services';
import { stateOverrideConstants } from '../constants/stateOverrideConstants';
import { IUpdateOverrideOptions } from '../interfaces';
import store from '../store';
import { selectGraphDataAll, selectGraphDataRangeAll } from '../selectors/graphData/graphDataSelector';
import { AxiosResponse } from 'axios';

/**
 * Unit related actions
 *
 * @type {Object}
 */
export const statesOverridesActions = {

    /**
     * store state override
     *
     * @param { IStatesOverride } stateOverride
     * @param { IUpdateOverrideOptions } options
     * 
     * @return {Promise<Object>}
     */
    store: (stateOverride: IStatesOverride, options?: IUpdateOverrideOptions) => {

        //Action creators
        const success = (stateOverride: IStatesOverride) => {

            return {
                type: stateOverrideConstants.STORE_SUCCESS,
                stateOverride,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: stateOverrideConstants.STORE_FAILURE,
                errors,
            };

        }, service = new StatesOverridesService(),
         graphDataService = new GraphDataService();

        const { sensor } = stateOverride;

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {


            const graphDataMap = selectGraphDataAll(store.getState()),
                graphDataRangeMap = selectGraphDataRangeAll(store.getState());

            service.store(stateOverride)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                })
                .then(() => {
                    if (options) {

                        graphDataService.item(sensor, options)
                            .then(({ data }: AxiosResponse) => {

                                const [state] = data;

                                graphDataMap.set(state.id, state);
                                graphDataRangeMap.set(state.id, state);

                            });
                    }
                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },


    /**
     * Update state override
     *
     * @param { IStatesOverride } stateOverride
     *
     *  @return {Promise<Object>}
     */
    update: (stateOverride: IStatesOverride) => {

        //Action creators
        const success = (stateOverride: IStatesOverride) => {

            return {
                type: stateOverrideConstants.UPDATE_SUCCESS,
                stateOverride,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: stateOverrideConstants.UPDATE_FAILURE,
                errors,
            };

        }, service = new StatesOverridesService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.update(stateOverride)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },

    /**
     * Remove state override by ID
     *
     * @param { IStatesOverride } stateOverride
     *  @param { IUpdateOverrideOptions } options
     *
     *  @return {Promise<Object>}
     */
    delete: (stateOverride: IStatesOverride, options?: IUpdateOverrideOptions) => {


        const success = (sOverride: IStatesOverride) => {

            return {
                type: stateOverrideConstants.DELETE_SUCCESS,
                sOverride,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: stateOverrideConstants.DELETE_FAILURE,
                errors,
            };

        }, service = new StatesOverridesService(),
            graphDataService = new GraphDataService();

        const { sensor } = stateOverride;

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            const graphDataMap = selectGraphDataAll(store.getState()),
                graphDataRangeMap = selectGraphDataRangeAll(store.getState());

            service.remove(stateOverride)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(stateOverride));

                })
                .then(() => {
                    if (options) {

                        graphDataService.item(sensor, options)
                            .then(({ data }: AxiosResponse) => {

                                const [state] = data;

                                graphDataMap.set(state.id, state);
                                graphDataRangeMap.set(state.id, state);

                            });
                    }
                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },

};
