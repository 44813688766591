import { stateOverrideConstants } from '../../constants/stateOverrideConstants';
import { IError, IStatesOverride } from '../../interfaces';


interface IStateOverrideState {
    refresh: number;
    stateOverride: IStatesOverride | null;
    errors: IError;
}

interface IStateOverrideAction {
    type: string;
    stateOverride: IStatesOverride;
    errors: IError;
}

/**
 * State selection reducer. Controls form state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function stateOverride(state: IStateOverrideState = {
    refresh: Date.now(),
    stateOverride: null,
    errors: {},
}, action: IStateOverrideAction): IStateOverrideState {

    switch (action.type) {

        case stateOverrideConstants.UPDATE_SUCCESS:
        case stateOverrideConstants.STORE_SUCCESS:

            return {
                refresh: Date.now(),
                stateOverride: action.stateOverride,
                errors: {},
            };

        case stateOverrideConstants.UPDATE_FAILURE:
        case stateOverrideConstants.STORE_FAILURE:

            return {
                refresh: Date.now(),
                stateOverride: null,
                errors: action.errors,
            };


        default:

            return state;
    }
}
