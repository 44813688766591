import { trackPromise } from 'react-promise-tracker';
import ApiProvider from '../providers/apiProvider';
import { IStatesOverride } from '../interfaces';
import { AxiosResponse } from 'axios';

/**
 * Service to work with State Override API resources
 *
 * @class StatesOverridesService
 */
export class StatesOverridesService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/state-overrides(/:id)';
    }

    /**
     * Create new State Override
     *
     * @param {IStatesOverride} statesOverride
     *
     * @return {Promise<Object>}
     */
    store(statesOverride: IStatesOverride): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .post(this.url(), statesOverride, {
                    headers: this.headers,
                }),
        );
    }

    /**
     * Update State Override
     *
     * @param {IStatesOverride} statesOverride
     *
     * @return {Promise<Object>}
     */
    update(statesOverride: IStatesOverride): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .patch(this.url({ id: statesOverride.id }), statesOverride, {
                    headers: this.headers,
                }),
        );
    }

    /**
     * Remove State Override by ID
     *
     * @param {IStatesOverride} statesOverride
     *
     * @return {Promise<Object>}
     */
    remove(statesOverride: IStatesOverride): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .delete(this.url({ id: statesOverride.id }), {
                    headers: this.headers,
                }),
        );
    }
}
