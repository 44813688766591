import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { ObjectSchema, Shape } from 'yup';

import { FormActions } from '../../../core/actions';
import { ICause, IError } from '../../../core/interfaces';
import { Button, TextInput, ConfirmDialog } from '../../../core/ui/components';
import './StatesForm.scss';
import { RootState } from '../../../core/store';
import { FormControl, FormLabel, Radio, RadioGroup } from '@material-ui/core';


interface IFormValues {
    id?: number | null;
    name: string;
    color: string;
    frameColor: string;
    sensorCauseCode: number | null;
}

interface IProps {
    errors: IError;
    formOpened: boolean;
    toggleForm: (opened: boolean, name: string) => void;
    model: ICause | null;
    confirmAdd: (item: ICause, name: string) => void;
    confirmUpdate: (item: ICause, name: string) => void;
}

interface IState {
    initialValues: IFormValues;
    confirm: boolean;
    colorList: string[];
}

/**
 * Reason add/edit form
 *
 * @class ReasonForm
 */
class ReasonForm extends React.Component<IProps & WithTranslation, IState> {

    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: IProps & WithTranslation) {

        super(props);

        const { t, model } = this.props;

        this.state = {
            initialValues: {
                id: model && model.id ? parseInt(model.id.toString()) : null,
                name: model ? model.name.toString() : '',
                color: model?.color || '',
                frameColor: model?.frameColor || '',
                sensorCauseCode: model?.sensorCauseCode !== undefined ? model?.sensorCauseCode : null,
            },
            confirm: false,
            colorList: ['#75c36e', '#f0d186', '#d08259', '#fa5252', '#b3de8e', '#fcbe75', '#9D9C9C', '#d0d4d6', '#AC94FE', '#EA9999', '#F9CB9C', '#FDE599', '#A2C4C9', '#B4A7D6', '#40A8F7', '#F49831', '#FDF733', '#76F013'],
        };

        this.validationSchema = yup.object().shape({
            name: yup
                .string()
                .trim()
                .max(30, t('MAX_ITEM_LENGTH', { name: t('NAME'), length: '30' }))
                .required(t('STATE_IS_REQUIRED')),
            color: yup.string(),
            frameColor: yup.string(),
            sensorCauseCode: yup.number()
                .nullable()
                .integer(t('VALUE_MUST_BE_AN_INTEGER_NUMBER'))
                .min(0, t('THE_MINIMUM_VALUE_MUST_BE_GREATER_THAN_OR_EQUAL_TO', { value: 0 }))
                .test('check-number', t('VALUE_MUST_BE_AN_INTEGER_NUMBER'), (val) => !isNaN(Number.parseInt(val)))
                .test('max-length', t('MAX_ITEM_LENGTH', { name: t('STATE_ID'), length: '4' }), (val) => !isNaN(Number.parseInt(val)) && val.toString().length <= 4)
                .required(t('STATE_ID_IS_REQUIRED')),
        });

        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleCloseSidebar = this.handleCloseSidebar.bind(this);

        this.onConfirmDialog = this.onConfirmDialog.bind(this);

        this.handleCancel = this.handleCancel.bind(this);

        this.handleChangeColor = this.handleChangeColor.bind(this);

        this.handleChangeFrameColor = this.handleChangeFrameColor.bind(this);
    }

    /**
     * Component props update handler
     *
     * @param {IProps} nextProps Updated component properties
     */
    componentDidUpdate(nextProps: IProps) {

        const { model } = this.props;

        if (nextProps.model !== model && model && model.id) {

            this.setState({
                initialValues: {
                    id: parseInt(model.id.toString()),
                    name: model.name.toString() || '',
                    color: model.color || '',
                    frameColor: model.frameColor || '',
                    sensorCauseCode: model.sensorCauseCode || null,
                },
            });
        }
    }

    /**
     * Form validation schema
     *
     * @type {ObjectSchema}
     */
    private readonly validationSchema: ObjectSchema<Shape<Record<string, unknown>, IFormValues>>;


    /**
     * Reason form submit handler
     *
     * @param {IFormValues} values
     */
    handleSubmit(values: IFormValues) {

        if (values.name) {

            this.setState({ confirm: true, initialValues: values });

        }
    }

    /**
     * Reason form cancel handler
     */
    handleCancel() {

        this.setState({ confirm: false });

    }

    /**
     * Handler cancel dialog form
     */
    onConfirmDialog() {

        const { initialValues } = this.state;
        const { errors } = this.props;

        if (!initialValues.id) {

            this.props.confirmAdd({
                name: initialValues.name,
                color: initialValues.color,
                frameColor: initialValues.frameColor,
                sensorCauseCode: initialValues.sensorCauseCode,
            }, 'reason');

        } else {

            this.props.confirmUpdate({
                id: initialValues.id,
                name: initialValues.name,
                color: initialValues.color,
                frameColor: initialValues.frameColor,
                sensorCauseCode: initialValues.sensorCauseCode,
            }, 'reason');
        }

        if (!errors || (errors && !errors.field)) {


            this.handleCancel();
        }
    }

    /**
     *  Close sidebar handler
     */
    handleCloseSidebar() {

        this.props.toggleForm(this.props.formOpened, '');

    }

    /**
     * Color Change Handler
     *
     * @param {string} event
     */
     handleChangeColor(event: React.ChangeEvent<HTMLInputElement>) {

        const { initialValues } = this.state;

        initialValues.color = event.currentTarget.value;

        this.setState({ initialValues: initialValues });
    }

    /**
     * Color frame Change Handler
     *
     * @param {string} event
     */
     handleChangeFrameColor(event: React.ChangeEvent<HTMLInputElement | any>, props: FormikProps<IFormValues>) {

        const { value } = event.target;

        const frameColor = props.values.frameColor !== value ? value : '';

        props.setFieldValue('frameColor', frameColor);
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render(): JSX.Element {

        const { t, errors = {} } = this.props,
            { initialValues } = this.state;

        return (
            <div className={'form-add-edit-states'}>
                <h3 className={'title'}>{t(!initialValues.id ? 'ADD_STATE' : 'EDIT_STATE')}</h3>
                {errors && errors.field &&
                <div className="common-error">
                    {errors.field === 'sensorCauseCode' 
                        ? t('STATE_ID_ALREADY_TAKEN') 
                        : t('THE_STATE_HAS_ALREADY_BEEN_TAKEN')
                    }
                </div>}
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={this.validationSchema}
                    onSubmit={this.handleSubmit}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit} noValidate style={{ paddingBottom: 80 }}>
                            <TextInput
                                className={
                                    'form-field ' +
                                    (props.touched.name ? (props.errors.name || errors.field === 'name') ? 'error-field' : 'success-field' : '')
                                }
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                label={this.props.t('NAME_STATE')}
                                value={props.values.name}
                                name="name"
                                type="text"
                                placeholder={this.props.t('NAME_STATE')}
                                inputProps={{ maxLength: 50 }}
                            >
                                {((props.touched.name && props.errors.name) || errors.field === 'name') &&
                                <div
                                    className="validation-massage"
                                >{props.errors.name}
                                </div>
                                }
                            </TextInput>
                            <TextInput
                                className={
                                    'form-field ' +
                                    (props.touched.sensorCauseCode ? (props.errors.sensorCauseCode || errors.field === 'sensorCauseCode') ? 'error-field' : 'success-field' : '')
                                }
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                label={this.props.t('STATE_ID')}
                                value={props.values.sensorCauseCode}
                                name="sensorCauseCode"
                                type="number"
                                placeholder={this.props.t('STATE_ID')}
                                inputProps={{ maxLength: 50 }}
                            >
                                {((props.touched.sensorCauseCode && props.errors.sensorCauseCode) || errors.field === 'sensorCauseCode') &&
                                    <div
                                        className="validation-massage"
                                    >{props.errors.sensorCauseCode}
                                    </div>
                                }
                            </TextInput>
                            <div>
                                <FormControl
                                    component="fieldset"
                                    className={'list-color-radio-btn'}
                                >
                                    <FormLabel
                                        component="legend"    
                                    >
                                        {this.props.t('CHOOSE_COLOR')}
                                    </FormLabel>
                                    <RadioGroup 
                                        aria-label="position"
                                        name="position" 
                                        value={props.values.color}
                                        onChange={props.handleChange}
                                        className={
                                            'form-field ' +
                                            (props.touched.color ? props.errors.color ? 'error-field' : 'success-field' : '')
                                        }
                                        row
                                    >
                                        {this.state.colorList.map((value) =>
                                            (
                                                <Radio
                                                    key={value}
                                                    value={value}
                                                    style={{ color: value }}
                                                    onChange={this.handleChangeColor}
                                                    name="color"
                                                    inputProps={{ 'aria-label': '$(value) ' }}
                                                />
                                            ),
                                        )}
                                    </RadioGroup>
                                </FormControl>
                            </div>

                            {props.values.color &&
                                <div>
                                <FormControl
                                    component="fieldset"
                                    className={'list-color-radio-btn'}
                                >
                                    <FormLabel
                                        component="legend"    
                                    >
                                        {this.props.t('CHOOSE_FRAME_COLOR')}
                                    </FormLabel>
                                    <RadioGroup 
                                        aria-label="position"
                                        name="position" 
                                        value={props.values.frameColor}
                                        onChange={props.handleChange}
                                        className={
                                            'form-field ' +
                                            (props.touched.frameColor ? props.errors.frameColor ? 'error-field' : 'success-field' : '')
                                        }
                                        row
                                    >
                                        {this.state.colorList.map((value) =>
                                            (
                                                <Radio
                                                    key={value}
                                                    value={value}
                                                    style={{ color: value }}
                                                    onClick={(e) =>  this.handleChangeFrameColor(e, props)}
                                                    name="frameColor"
                                                    inputProps={{ 'aria-label': '$(value) ' }}
                                                />
                                            ),
                                        )}
                                    </RadioGroup>
                                </FormControl>
                                </div>
                            }

                            <div className="button-row">
                                <Button
                                    type="button"
                                    color="primary"
                                    onClick={this.handleCloseSidebar}
                                >
                                    {t('CANCEL')}
                                </Button>
                                <Button type="submit" color="secondary">
                                    {t(initialValues.id ? 'SAVE_CHANGES' : 'SAVE')}
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
                {this.state.confirm?
                    <ConfirmDialog
                        heading={t(initialValues.id ? 'CHANGE_STATE_Q_CONFIRM' : 'ADD_STATE_Q_CONFIRM')}
                        onAccept={this.onConfirmDialog}
                        onClose={this.handleCancel}
                        open={this.state.confirm}
                    />
                    :
                    null
                }
            </div>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const { errors } = state.statesList,
        { formOpened } = state.form;

    return {
        errors,
        formOpened,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    toggleForm: FormActions.toggle,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReasonForm));